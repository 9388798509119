import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { graphQL_shema } from "../page/utils/graphql";
import { useTranslation } from "react-i18next";
import "../index.css";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  DatePicker,
  ConfigProvider,
  Menu,
  Dropdown,
  message,
  Space,
  Tooltip,
  Alert,
  Spin,
} from "antd";
import fr_FR from "antd/lib/locale/fr_FR";
import en_US from "antd/lib/locale/en_US";

import {
  SearchOutlined,
  FilterOutlined,
  CalendarOutlined,
  PlusSquareOutlined,
  SyncOutlined,
  DeleteOutlined,
  DownOutlined,
  VideoCameraOutlined,
  DownloadOutlined,
  
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { setConfirmMultiDelete, setcurrentStatus, setshowDivsConditions } from "../redux/actions";
import moment from "moment"; // constante pour modifier le format de DatePicker
import { GraphQLFetchData } from "../page/utils/graphQLFetchData";
import { useSelector, useDispatch } from "react-redux";
import {
  setPaginationProps,
  setLoadingDeleteShowVideo,
  setFilterVideosActions,
} from "../redux/actions";
import { useMediaQuery } from "@material-ui/core";

const { Option } = Select;
var items1;
var clicked = false;

function Header({ searchRow, recoverDeleteItem, GetEvent }) {
  const condition = useSelector((state) => state.reducer.showDivsCondition);
  const deleteMultiEvents  = useSelector((state) => state.reducer.deleteMultiEvents);
 

  const { deleteRow } = GraphQLFetchData();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false); // state to show filter div
  const [values, setvalues] = useState([]); // pour stocker les valeurs des selections
  const history = useHistory();
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const [runDeleteItem, setRunDeleteItem] = useState(false);
  const [showNumDeletedRow, SetshowNumDeletedRow] = useState(false);
  const [hoverFilter, SetHoverFilter] = useState(false);
  const { RangePicker } = DatePicker;
  const [ExportDateRange, setExportDateRange] = useState("");
  const [ExportLink, setExportLink] = useState("");
  const [DownloadExcelFile, setDownloadExcelFile] = useState(false);
  // permission button to user
  const [delete_multiple_permission] = useState(false); // pour la permission du bouton delete multiple
  const ListVideos = useSelector((state) => state.reducer.ListVideos);
  const paginationProps = useSelector((state) => state.reducer.pagination);
  const loadingDelete = useSelector((state) => state.reducer.loadingDelete);
  const conditionshowdivs = useSelector(
    (state) => state.reducer.showDivsCondition
  );
  const FilterVideos = useSelector((state) => state.reducer.FilterVideos);
  const { event_module } = useSelector(
    (state) => state.ReducerUserData.modules
  );
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
  
  const internetFilter = useSelector(state=>state.reducer.showDivsCondition.type_diffusion)
  const size = useMediaQuery("(max-width: 670px)");
  useEffect(() => {
    function goto(event) {
      var noRedirect =
        " .filterIcon , .filterIcon * , .div-filter , .div-filter * ,.ant-select-dropdown *, .ant-select-item-option-content , .ant-picker-cell-inner , .ant-picker-dropdown *";
      if (!event.target.matches(noRedirect)) {
        setShow(false);
        SetHoverFilter(false);
      }
    }

    document.body.addEventListener("click", goto);
  }, []);
  // handle select filter event
  const searchParams = new URLSearchParams(window.location.search);
  const handleSelectFilter = async (e) => {
    await dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    );
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "rowId",
        showDivsConditionsValue: [],
      })
    );
    searchParams.set("filter", e);
    const modifiedQuery = searchParams.toString();
    history.push(`${window.location.pathname}?${modifiedQuery}`);
    window.parent.postMessage({ 
      info: "event",
      value:  e == "live" ? 'ongoing':e 
      },
    "*");

  };
  
  //Menu Diffusion
  const menuDiffusion = (
    <Menu>
      <Menu.Item key="0" onClick={() => handleSelectFilter("all")}>
        <span>{t("Diffusion.menuDiffusionTous")}</span>
      </Menu.Item>
      <Menu.Item key="0" onClick={() => handleSelectFilter("archived")}>
        <span>{t("Diffusion.menuDiffusionArchived")}</span>
      </Menu.Item>
      {event_module?.event_list_dtable_events_ongoing_action?.authorized && (
        <Menu.Item key="1" onClick={() => handleSelectFilter("live")}>
          <span has-permission="event_list_dtable_events_ongoing">
            {t("Diffusion.menuDiffusionEnDirect")}
          </span>
        </Menu.Item>
      )}
      <Menu.Item key="3" onClick={() => handleSelectFilter("upcoming")}>
        <span>{t("Diffusion.menuDiffusionUpcoming")}</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelectFilter("trash")}>
        <span>{t("Diffusion.menuDiffusionTrash")}</span>
      </Menu.Item>
    </Menu>
  );

  // function pour récuperer les données pour les stations et les thémes
  const { data } = useQuery(graphQL_shema().Get_Stations_Themes_Categories, {
    variables: { empty: "" },
  });
  // export excel file
  const [getExportExcel,{loading:exportLoading}] = useLazyQuery(graphQL_shema().Get_Export_Excel, {
    variables: {},
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.getExcelFile?.code === 200) {
        setExportLink(data.getExcelFile?.link);
        if (ExportDateRange) {
          setDownloadExcelFile(true);
        }
      } else {
        setDownloadExcelFile(false);
        message.error(t("showEvent.failed_export"))
      }
    },
    onError: (err) => {
      setDownloadExcelFile(false);
      message.error(t("showEvent.failed_export"))
    },
  });
  // date export
  const onChangeRange = async (date) => {
    setExportDateRange(date);
    if (date)
      getExportExcel({
        variables: {
          start_date: moment(date?.[0]?._d).utc(1).format("YYYY-MM-DD HH:mm Z"),
          end_date: moment(date?.[1]?._d).utc(1).format("YYYY-MM-DD HH:mm Z"),
        },
      });
    else setDownloadExcelFile(false);
  };
  const ExportExcelFunction = () => {
    if (DownloadExcelFile) {
      window.open(ExportLink);
      message.open({
        type: "success",
        content: t("ExportExcel.successMessage"),
      });
    } else {
      message.open({
        type: "error",
        content: t("ExportExcel.errorMessage"),
      });
    }
  };

  // Query get Action (permission )
  //Mutation to take Name of Client
  const [clientName] = useMutation(graphQL_shema().CLIENT_NAME, {
    variables: { clientName: "" },

    onCompleted: (data) => {
      if (data.automatedEventUpdates.code === "200") {
        success_client_name();
      } else if (data.automatedEventUpdates.code === "400") {
        error_client_name(400);
      }
    },
  });
  //delete multiple row
  const handleDeleteIcon = async () => {
    let filterListVid = [];

    // dispatch show Alert
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "clickDeleteIcon",
        showDivsConditionsValue: false,
      })
    );
    setTimeout(() => {
      dispatch(
        setshowDivsConditions({
          showDivsConditionsName: "clickDeleteIcon",
          showDivsConditionsValue: true,
        })
      );
    }, 3000);

    // Time out to Run API Delete
    items1 = setTimeout(async () => {
      await deleteRow().then(async (res) => {
        const {
          data: { deleteEventOrganizers },
        } = await res;
        let deletedItems = deleteEventOrganizers.deleteditems || [];
        let notDeletedItems = deleteEventOrganizers.undeleteditems || [];
        filterListVid = ListVideos.data.filter((item) => {
          return !deletedItems.includes(item.id);
        });
        if (filterListVid.length === 0) {
          await dispatch(
            setPaginationProps({
              PaginationPropsNameChange: "current",
              PaginationPropsValueChange:
                paginationProps.current !== 1 ? paginationProps.current - 1 : 1,
            })
          );
        }
        await GetEvent();
        await dispatch(
          setshowDivsConditions({
            showDivsConditionsName: "rowId",
            showDivsConditionsValue: [...notDeletedItems],
          })
        );
      });
    }, 3000);

    dispatch(
      setLoadingDeleteShowVideo({
        LoadingDeleteName: "loadingDelete",
        LoadingDeleteValue: true,
      })
    );
  };
  //clear Time Out
  const handleclearTimeout = () => {
    // dispatch loading Delete Button
    dispatch(
      setLoadingDeleteShowVideo({
        LoadingDeleteName: "loadingDelete",
        LoadingDeleteValue: false,
      })
    );

    SetshowNumDeletedRow(false);
    setRunDeleteItem(true);
    setTimeout(() => {
      setRunDeleteItem(false);
    }, 3000);
    clearTimeout(items1);
    recoverDeleteItem();
  };

  // success take Clien Name
  const success_client_name = () => {
    message.success({
      content: `${t("ClientName.successMessage")}`,
    });
  };
  //Error take Client Name
  const error_client_name = (code) => {
    const messageERROR = {
      400: `${t("Message.errorMessage")}`,
    };
    message.warning({
      content: messageERROR[code],
      duration: 1.5,
    });
  };
  // routing function
  const handleAddNew = () => {
    history.push(`/events/addevent/`, {
      action: "create",
    });

    window.parent.postMessage(
      {
        info: "event_add",
        value: "add",
      },
      "*"
    );
  };

  // function pour changer DataPicker
  function onChange(date, dateString) {
    dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "dateStingValue",
        FilterVideosValueChange: dateString,
      })
    );
  }
  // function pour prendre les valeurs de "select"
  const OnChange_Select = async (name, value, action) => {
    (await !value)
      ? setvalues({ ...values, [name]: 0 })
      : setvalues({ ...values, [action.name]: action.value });

    // Value of Select with Redux
    !value
      ? dispatch(
          setFilterVideosActions({
            FilterVideosNameChange: name,
            FilterVideosValueChange: "",
          })
        )
      : dispatch(
          setFilterVideosActions({
            FilterVideosNameChange: name,
            FilterVideosValueChange: action.children,
          })
        );

    // Values of variables Query GetLives
    !value
      ? dispatch(
          setFilterVideosActions({
            FilterVideosNameChange:
              name === "stationIDName"
                ? "stationID"
                : name === "themeIdName"
                ? "themeId"
                : null,
            FilterVideosValueChange: 0,
          })
        )
      : dispatch(
          setFilterVideosActions({
            FilterVideosNameChange: action.name,
            FilterVideosValueChange: action.value,
          })
        );
  };
  // fonction bouton Filtrer
  const handleClickFilter = async () => {
    
  await  Promise.all([   
      dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    ),
     dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "dateString",
        FilterVideosValueChange: FilterVideos.dateStingValue,
      })
    ),
     dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "stationIDRedux",
        FilterVideosValueChange: FilterVideos.stationID,
      })
    ),
     dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "themeIdRedux",
        FilterVideosValueChange: FilterVideos.themeId,
      })
    )])  
  };

  // fonction pour rénisialiser state
  const renitialiserRow = async (value) => {
    //setvalues('');
    setShow(false);

    
    await dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "themeIdName",
        FilterVideosValueChange: "",
      })
    );
    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "stationIDName",
        FilterVideosValueChange: "",
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "dateStingValue",
        FilterVideosValueChange: "",
      })
    );
    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "stationID",
        FilterVideosValueChange: 0,
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "themeId",
        FilterVideosValueChange: 0,
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "dateString",
        FilterVideosValueChange: "",
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "stationIDRedux",
        FilterVideosValueChange: 0,
      })
    );

    await dispatch(
      setFilterVideosActions({
        FilterVideosNameChange: "themeIdRedux",
        FilterVideosValueChange: 0,
      })
    );
  };
  // handle intranet filter
  const handleIntranetFilter = async () => {
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "rowId",
        showDivsConditionsValue: [],
      })
    );
    await dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    );
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "type_diffusion",
        showDivsConditionsValue: internetFilter==="intranet" ? "" : "intranet",
      })
    );
  };

  // handle internet filter
  const handleInternetFilter = async () => {
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "rowId",
        showDivsConditionsValue: [],
      })
    );
    await dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    );
    await dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "type_diffusion",
        showDivsConditionsValue: internetFilter==="internet" ? "" : "internet",
      })
    );
  };
  // click suffixx input
  const handlClickSuffix = () => {
    setShow(!show);
    if (clicked) {
      SetHoverFilter(false);
      clicked = false;
    } else {
      SetHoverFilter(true);
      clicked = true;
    }
  };
  // handle Mise a jour
  const handleMiseAJour = () => {
    clientName();
  };
const handleDeleteMulti =() =>{
  dispatch(setConfirmMultiDelete(true)) 
}

const [filterName, setFilterName] = useState('');
 

  useEffect(() => {
    let filter = searchParams.get("filter");
 
    let name = "";
    let filterName = "";
    switch (filter) {
      case "all":
        name = t("Diffusion.menuDiffusionTous");
        filterName = "all";
        break;
      case "archived":
        name = t("Diffusion.menuDiffusionArchived");
        filterName = "archived";

        break;
      case "upcoming":
        name = t("Diffusion.menuDiffusionUpcoming");
        filterName = "upcoming";

        break;
      case "trash":
        name = t("Diffusion.menuDiffusionTrash");
        filterName = "trash";

        break;
      default:
        name = t("Diffusion.menuDiffusionEnDirect");
        filterName = "live";
        break;
    }
    dispatch(setcurrentStatus(filterName));
    setFilterName(name);
  }, [dispatch, searchParams.get("filter"), currentLanguage]);

 

  return (
    <>
      <Row>
        <Col span={24} className="header-event">
          <Row className="row-event">
            <Col span={11} xs={12} className="col1-row-event">
              <span className="text-event">
                {searchParams.get("filter") === "all"
                  ? `${t("showEvent.AllEvent")}`
                  : searchParams.get("filter") === "archived"
                  ? `${t("showEvent.ArchivedEvent")}`
                  : searchParams.get("filter") === "upcoming"
                  ? `${t("showEvent.UpComingEvent")}`
                  : searchParams.get("filter") === "trash"
                  ? `${t("showEvent.DeletedEvent")}`
                  : t("showEvent.LiveEvent")}
              </span>
            </Col>
            <Col span={11} xs={12} className="col2-row-event">
              {event_module?.event_post_add_action?.authorized ? (
                <Button
                  has-permission="event_post_add"
                  className="btn_add_event"
                  icon={<PlusSquareOutlined />}
                  type="primary"
                  style={{ float: "right" }}
                  onClick={handleAddNew}
                >
                  {size ? t("showEvent.Add") : t("showEvent.AjoutEvénement")}
                </Button>
              ) : null}
              <Tooltip title={t("showEvent.update_description")}>
                <Button
                  onClick={() => handleMiseAJour()}
                  icon={<SyncOutlined />}
                  style={{ float: "right", marginRight: 12 }}
                  className="btn_Update"
                >
                  {t("showEvent.MiseAjour")}
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="row-action">
            <Col className="col-calender">

              <div className="div_filter_intranet_internet">
   
             {deleteMultiEvents  && (
              <Tooltip className="tooltip_delete" title="Delete">
                  <span
                    className="btn_delete"
                    onClick={handleDeleteMulti}
                  >
                    <DeleteOutlined  d="icon_delete" className="icon_delete" />
                  </span>
                </Tooltip>
)}

              </div>
              <div
                className="calendar"
                onClick={() => history.push("/events/calendar-event/")}
              >
                <CalendarOutlined className="icon_calender" />
                <span className="text">{t("showEvent.AficherCalendrier")}</span>
              </div>
              <div className={"div_dropdown_diffusion"}>
                <Dropdown overlay={menuDiffusion} trigger={["click"]}>
                  <Button>
                    <VideoCameraOutlined />
                    {filterName}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Col>
            <Col className="col-search">
              <Input
                placeholder={t("showEvent.Placeholderfilterinput")}
                prefix={
                  <SearchOutlined
                    style={{ color: "rgba(0, 0, 0, 0.25)", marginLeft: "10px" }}
                  />
                }
                suffix={
                  <div
                    className="filterIcon"
                    style={{
                      color: "rgba(0, 0, 0, 0.25)",
                      padding: "0.8em",
                      width: "100%",
                      height: "100%",
                      borderLeft: "1px solid #d9d9d9",
                      cursor: "pointer",
                    }}
                    onClick={handlClickSuffix}
                    id={hoverFilter === true ? "hover" : null}
                  >
                    <FilterOutlined className="class_icon_filter" />
                  </div>
                }
                className="input-search"
                onKeyDown={searchRow}
                onChange={(e) =>
                  // dispatch loading Delete Button
                {  dispatch(
                    setFilterVideosActions({
                      FilterVideosNameChange: "searchFake",
                      FilterVideosValueChange: e.target.value,
                    })
                  )
                  dispatch(
                    setFilterVideosActions({
                      FilterVideosNameChange: "search",
                      FilterVideosValueChange: e.target.value,
                    })
                  )}
                
                }
                 
                  
                value={FilterVideos.searchFake}
              />
            </Col>
          </Row>

          {show ? (
            <Row className="filter-row">
              <Col className="col-filter">
                <div ref={wrapperRef} id="id_div_filter" className="div-filter">
                  <div className="div-select">
                    <Select
                      name="stationIDName"
                      onChange={(name, action) => {
                        OnChange_Select("stationIDName", name, action);
                      }}
                      value={
                        FilterVideos.stationIDName
                          ? FilterVideos.stationIDName
                          : null
                      }
                      className="select1"
                      showSearch
                      placeholder={t("showEvent.Stations")}
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {data.getInfoForm.stations.map((station) => {
                        return (
                          <Option
                            id="option_select"
                            value={station.id}
                            name="stationID"
                            key={station.id}
                          >
                            {station.name}
                          </Option>
                        );
                      })}
                    </Select>

                    <Select
                      name="themeIdName"
                      onChange={(name, action) => {
                        OnChange_Select("themeIdName", name, action);
                      }}
                      className="select2"
                      allowClear
                      showSearch
                      placeholder={t("showEvent.Theme")}
                      optionFilterProp="children"
                      value={
                        FilterVideos.themeIdName
                          ? FilterVideos.themeIdName
                          : null
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {data.getInfoForm.themes.map((theme) => {
                        return (
                          <Option
                            ref={wrapperRef}
                            value={theme.id}
                            name="themeId"
                            key={theme.id}
                          >
                            {theme.title}
                          </Option>
                        );
                      })}
                    </Select>
                    <Space direction="vertical">
                      <DatePicker
                        placeholder={t("showEvent.DatePicker")}
                        id="datePicker"
                        className="datePicker"
                        onChange={onChange}
                        value={
                          FilterVideos.dateStingValue
                            ? moment(FilterVideos.dateStingValue, "YYYY-MM-DD")
                            : ""
                        }
                      />
                    </Space>
                  </div>

                  <div className="div-button-filter">
                    <Button
                      className="btn_renitialiser"
                      onClick={() => renitialiserRow(setvalues(""))}
                    >
                      {t("showEvent.Rénitialiser")}
                    </Button>
                    <Button
                      className="btn_filtrer"
                      type="primary"
                      onClick={() => {
                        handleClickFilter();
                        setShow(false);
                      }}
                    >
                      {t("showEvent.Filter")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
          <Row>
            {conditionshowdivs.rowId.length ===
            0 ? null : delete_multiple_permission ===
              false ? null : showNumDeletedRow === false ? (
              <Col className="col-delete-elements">
                <div className="div-delete-elements">
                  <Tooltip title={t("Delete.DeleteRow")}>
                    <Button
                      type="secondary"
                      icon={<DeleteOutlined className="icon_delete" />}
                      onClick={handleDeleteIcon}
                      loading={loadingDelete.loadingDelete}
                    />
                  </Tooltip>
                  <span className="span_supprimer">
                    {conditionshowdivs.rowId.length || ""}
                  </span>
                  <span className="span_supprimer">
                    {t("showEvent.ElementSelected")}
                  </span>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        {!condition.clickDeleteIcon ? (
          <div className="div_alert">
            <Alert
              id="ant-alert"
              message={
                condition.elements_selected > 1
                  ? `${t("Message.ElementsSelecteds")}`
                  : `${t("Message.ElementSelected")}`
              }
              banner
              action={
                <Button
                  disabled={runDeleteItem}
                  onClick={handleclearTimeout}
                  className="btn_annuler"
                  size="small"
                  type="text"
                >
                  {t("Translate.Annuler")}
                </Button>
              }
            />
          </div>
        ) : null}
      </Row>
      <Row span={24} className="exportRow">
        <Col span={24} className="export">
          <Space direction="vertical">
            <ConfigProvider locale={currentLanguage == "fr" ? fr_FR : en_US}>
              <RangePicker
                className="span_rangepicker"
                popupClassName="span_rangepicker_popup"
                showTime
                onChange={onChangeRange}
                value={ExportDateRange}
                size="large"
              />
            </ConfigProvider>
          </Space>
          <span className="spinning-btn">
            <Spin spinning={exportLoading}>
              <Button
                className="btn_export"
                type="primary"
                icon={<DownloadOutlined />}
                size="large"
                disabled={!DownloadExcelFile}
                onClick={ExportExcelFunction}
              >
                Export
              </Button>
            </Spin>
          </span>
        </Col>
      </Row>
    </>
  );
}

export default Header;
