import { Calendar, Badge, Row, Col, Spin, Tag } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import "moment-timezone";
import { useLocation } from "react-router-dom";
import { graphQL_shema } from "../utils/graphql";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useSelector } from "react-redux";
export const Calendar_event = () => {
  const { language } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [allow, setAllow] = useState(false);
  const [calendarValues, setCalendarValues] = useState([]);
  const [dateTime, setDateTime] = useState(moment());
  const [mode, setMode] = useState("month");
  const searchParams = new URLSearchParams(window.location.search);
  const status = useSelector((state) => state.reducer.currentStatus);

  const [QueryCalendar] = useLazyQuery(graphQL_shema().Get_Calendar_Data, {
    fetchPolicy: "cache-and-network",
    onCompleted: async (data) => {
      if (data.getCalendar) {
        await data.getCalendar.forEach((element) => {
          moment(element.date.date).month() === dateTime.month()
            ? (element.date.isAMomentObject = true)
            : (element.date.isAMomentObject = false);
        });
        setCalendarValues(data.getCalendar);
      }
      setAllow(true);
    },
  });

  const history = useHistory();
  const getListData = (value) => {
    let listData = [];
    if (value && Object.keys(value).length > 0) {
      if (calendarValues) {
        calendarValues.forEach((element) => {
          switch (value.year() + "-" + value.month() + "-" + value.date()) {
            case moment(element.date.date).year() +
              "-" +
              moment(element.date.date).month() +
              "-" +
              moment(element.date.date).date():
              listData = [
                ...listData,
                {
                  id: element.id,
                  type: element.startRecord,
                  content: element.content,
                  style: element.date.isAMomentObject,
                },
              ];
              break;
          }
        });
      }
    }
    return listData || [];
  };
 const getColor = (type) => {
  switch (type) {
    case -1 : // à venir
     return "processing"
    case 0 : // terminé
     return "default"
    case 1 : //en cours
     return "success"
    default :
     return "default"
     
  }

 }
  const dateCellRender = (value) => {
    const listData = getListData(value);
  return (
      <div>
        {allow && (
          <ul className="events">
            {listData.map((item) => (
              <li key={item.id}>
                <Tag color={getColor(item.type)}>
                  <Badge
                    style={{ opacity: !item.style && "0.3" }}
                    status={item.type}
                    text={item.content}
                  />
                </Tag>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const getMonthData = (value) => {
    let arrInProgress = [];
    let arrArchived = [];
    let arrComing = [];
    let totalEvents = [];
    let trashEvents = [];
    let countTrash = 0;
    let countInProgress = 0;
    let countArchived = 0;
    let countComing = 0;

    if (value && Object.keys(value).length > 0) {
      if (calendarValues) {
        calendarValues.forEach((element) => {
          if (
            moment(element.date.date).year() +
              "-" +
              moment(element.date.date).month() ===
            value.year() + "-" + value.month()
          ) {
            if(element.status !== 'trash')
           {              
            switch (element.startRecord) {
              case 0:
                arrArchived = [...arrArchived, element];
                countArchived = arrArchived.length;
                break;
              case 1:
                arrInProgress = [...arrInProgress, element];
                countInProgress = arrInProgress.length;
                break;
              case -1:
                arrComing = [...arrComing, element];
                countComing = arrComing.length;
                break;
              default:
                arrComing = [...arrComing, element];
                countComing = arrComing.length;

                break;
            }}
            else 
              {               
                trashEvents = [...trashEvents, element];
                countTrash = trashEvents.length;}
          }
        });
      }
    }
    totalEvents = [
      countInProgress > 0 && {
        id: 1,
        type: "En cours",
        content: countInProgress,
        style: "success",
      },
      countComing > 0 && {
        id: 2,
        type: "A venir",
        content:countComing,
        style: "processing",
      },
      countArchived > 0 && {
        id: 3,
        type: t("showEvent.Finir"),
        content: countArchived,
        style: "default",
      },
      countTrash > 0 && {
        id: 4,
        type: t("showEvent.supprimés"),
        content: countTrash,
        style: "default",
      },
    ];
    return totalEvents;
  };
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div
        className="notes-month"
        onClick={() => OnPanelChange(value, "month")}
      >
        {num.map((item, idx) => {
          if (item) {
            return (
              <div key={idx}>
                <Tag color={item.style}>
                  <Badge
                    style={{ opacity: !item.style && "0.3" }}
                    status={item.style}
                    text={
                      <span>
                        {item.content} {item.type}
                      </span>
                    }
                  />
                </Tag>
              </div>
            );
          }
          else return null
        })}
      </div>
    ) : null;
  };
  useEffect(() => {
    OnPanelChange(moment(new Date()), "month");
  }, []);
  const OnPanelChange = async (date, mode) => {
    let month_number = date.month() + 1;
    let month_before =
      month_number === 1
        ? "12"
        : month_number < 11
        ? "0" + (month_number - 1).toString()
        :  (month_number - 1).toString();
    let year_before = month_before === "12" ? date.year() - 1 : date.year();
    let month =
      date.month() < 9
        ? "0" + month_number.toString()
        : month_number.toString();
    let year = date.year();
    let month_after =
      month_number === 12
        ? "01"
        : month_number < 9
        ? "0" + (month_number + 1).toString()
        :  (month_number + 1).toString();
    let year_after = month_after === "01" ? date.year() + 1 : date.year();
    let query = {
      variables: {
        dates: [
          year_before + "-" + month_before,
          year + "-" + month,
          year_after + "-" + month_after,
        ],
        input: {
          limit: 0,
          month: month,
          offset: 0,
          status:status,
          year: year,
        },
      },
    };
    setMode(mode);
    if (mode === "year") {
      query = { variables: {  dates: [
        year_before + "-" + month_before,
        year + "-" + month,
        year_after + "-" + month_after,
      ],
       input: {
        limit:0,
        offset:0,
        month: 0,
        status:status,
        year: year,
      }} };
      setAllow(false);
    } else {
      setAllow(true);
    }
    await setDateTime(date);
    QueryCalendar(query, mode === "year" ? year : date);
  };

  useEffect(() => {
    location.state && localStorage.setItem("idUser", location.state.idUser);
    location.state &&
      localStorage.setItem("initialTypeEvent", location.state.initialTypeEvent);
  }, []);

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  // Disable Date Calendar
  const disableDate = (current) => {
    return current && current.valueOf() < Date.now();
  };
  const getTitle = () => {
    switch (status) {
      case "all":
        return t("showEvent.AllEvent");
      case "archived":
        return t("showEvent.ArchivedEvent");
      case "upcoming":
        return t("showEvent.UpComingEvent");
      case "trash":
        return t("showEvent.DeletedEvent");
      default:
        return t("showEvent.LiveEvent");
    }
  };
  const title = getTitle();
  return (
    <div className="ant-card ant-card-bordered">
      <div className="ant-card-body">
        <div className="header-event">
          <Row className="emp">
            <Col className="emp-col">
              <ArrowLeftOutlined
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => history.goBack()}
              />
              <span className="title">{t("Translate.Calendrier")} : {title}</span>
            </Col>
          </Row>
        </div>
        <Spin className="Spin" spinning={!allow}>
          <Calendar
            className="calendrier"
            // disabledDate={(current) => disableDate(current)}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
            onPanelChange={OnPanelChange}
            mode={mode}
          />
        </Spin>
      </div>
    </div>
  );
};
