import React, {Suspense} from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloProvider} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import {setContext} from 'apollo-link-context';
import {Provider} from "react-redux";
import store from "./redux/store";
import './i18n';
import {Spin} from "antd";


const link = createUploadLink({
    uri: window.process.env.API_EVENT_HOST + "query"
});

const authLink = setContext(() => {
    const token = localStorage.getItem("token")
    return {
        headers: {
        //    'client-name':'test',
            Authorization: token ? `Bearer ${token}` : "",
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
});


export default (
    <Suspense fallback={(<Spin className="Spin">
        <div></div>
    </Spin>)}>
        <Provider store={store}>
            { client &&
                <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
            }

        </Provider>
    </Suspense>
);